<template>
	<div id="Snatch">
		<div class="Snatch-box">
			<div class="Title">
				<p>提货条款与协议</p>
				<div class="Title-lower"></div>
			</div>
			<div class="Snatch-box-info">
				<div class="title">一、退款条款与免责条款</div>
				<p>1、用户理解并确认，在使用本条款项下的服务的过程中，可能会遇到不可抗力等风险因素，使得充值不到账情况。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成影响的客观事件，包括服务器异常、紧急突发bug、支付渠道延迟到账等问题。出现上述情况时，炫色XSCSGO 将努力在第一时间配合，及时进行修复，并保证充值到账。但此项并不可以作为退款理由</p>
				<p>2、本条款项下的服务同大多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒，威胁到用户的计算机信息和数据的安全，继而影响本条款项下的服务的正常使用等等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。</p>
				<p>3、用户理解并确认，本条款项下的服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险，因此导致的用户或第三方任何损失，炫色XSCSGO 及时进行修复，但在法律允许的范围内免责，并且拒绝不合理投诉。</p>
				<p>4、用户理解并确认，在使用本条款项下的服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的用户或第三方的任何损失，炫色XSCSGO 不承担任何责任。</p>
				<p>5、用户理解并确认，炫色XSCSGO 需要定期或不定期地对炫色XSCSGO 或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，平台无需为此承担任何责任，但炫色XSCSGO 应事先进行通告。</p>
				<div class="title">二、取回条款</div>
				<p>1、当用户取回的饰品缺货时，可以向客服申请补货，如供货商暂时缺货，请耐心等待。</p>
				<p>2、如果出现了取回失败、取回延迟到账问题，炫色XSCSGO 有义务第一时间为用户解决问题，保障用户权益。但这并不是用户退款或恶意投诉的理由。</p>
				<p>3、用户如果出现了误提，或账号被盗导致了提取，平台尽力帮助用户解决问题。但这并不是用户退款或恶意投诉的理由</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'Snatch',
		data(){
			return{
				
			}
		}
	}
</script>

<style lang="scss">
	.Snatch-box{
	     width: 10rem;
	     margin: 0 auto;
	     padding-top: 0.4rem;
	     .Snatch-box-info{
	         margin-top: 0.3rem;
	         .title{
	             font-size: 0.18rem;
	             color: #73d171;
	             height: 0.5rem;
	             line-height: 0.5rem;
	        }
	         p{
	             font-size: 0.16rem;
	             color: #fff;
	             line-height: 1.75em;
	        }
	    }
	}
	 
</style>
